import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RawIntlProvider } from 'react-intl'
import { SWRConfig } from 'swr'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { OverlayProvider } from '@react-aria/overlays'
import { SSRProvider } from '@react-aria/ssr'
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react'
import { wrapper } from 'src/redux/store'
import { intl } from 'src/i18n'
import { Toast } from 'src/components/Toast'
import { ENTITY } from 'src/consts'
import { setCurrentEntity } from 'src/redux/actions'
import { EventTracker } from 'src/elements'
import { ErrorBoundaryRefresh } from 'src/elements'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'src/utils/localStorage'
import { setSharedCurrency } from 'src/redux/thunks'
import { returnParamFromUrl } from 'src/360ui/utils/router'
import { knownCurrencies } from 'src/consts'
import { EmailVerificationMessage } from 'src/components/Auth'
import 'src/styles/css/index.scss'
import { frontendConfig } from '../config/frontendConfig'
import { checkAuthStatus } from 'src/redux/thunks/auth'
import { SessionExpireModal } from 'src/components/SessionExpireModal'
import { getCookie, setCookies } from 'cookies-next'

const CookiesBanner = dynamic(() => import('src/components/CookiesBanner'), {
  ssr: false,
})

const FormBanner = dynamic(
  () => import('src/components/GoogleForm/components/FormBanner'),
  {
    ssr: false,
  }
)

const StickyCorner = dynamic(
  () => import('src/components/Banners/StickyCorner'),
  { ssr: false }
)

if (typeof window !== 'undefined') {
  SuperTokensReact.init(frontendConfig())
}

const DefaultLayout = ({ children }) => children

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const isWidget = router.route.split('/')[1].includes('widget')
  const { isAuth } = useSelector(state => state.auth)
  const [sessionExpiredModal, setSessionExpiredModal] = useState(false)
  const [bannerVisible, setBannerVisible] = useState(true) // State for banner visibility

  // Check if the cookie is set on mount to control banner visibility
  useEffect(() => {
    const bannerClosed = getCookie('bannerClosed')
    if (bannerClosed) {
      setBannerVisible(false) // Hide banner if cookie is set
    }
  }, [])

  useEffect(() => {
    const { currentEntityByLocation } = pageProps
    dispatch(setCurrentEntity(currentEntityByLocation))
  }, [router, pageProps, dispatch])

  useEffect(() => {
    dispatch(checkAuthStatus())
  }, [dispatch])

  useEffect(() => {
    const currency =
      returnParamFromUrl('currency') || getLocalStorageItem('currency')

    const preparedCurrency = knownCurrencies[currency] ? currency : 'USD'

    if (preparedCurrency !== 'USD') {
      dispatch(setSharedCurrency(preparedCurrency, true))
    }
    setLocalStorageItem('currency', currency)
  }, [dispatch])

  const swrConfig = pageProps.fallback
    ? { fallback: pageProps.fallback }
    : undefined

  const Layout = Component.getLayout || DefaultLayout

  const handleCloseBanner = () => {
    setBannerVisible(false)
    setCookies('bannerClosed', true, { maxAge: 60 * 60 * 3 })
  }

  return (
    <SuperTokensWrapper
      onSessionExpired={() => {
        setSessionExpiredModal(true)
      }}
    >
      <SSRProvider>
        <OverlayProvider id='overlay-provider'>
          <ErrorBoundaryRefresh>
            <SWRConfig value={swrConfig}>
              <RawIntlProvider value={intl}>
                {/* Add new advertiser scripts and styles */}
                <Head>
                  <style>{`
                    @media only screen and (min-width: 0px) and (min-height: 0px) {
                      div[id^="wrapper-sevio-9ad182de-b462-428c-b5b0-9b4962b113f7"] {
                        width: 320px;
                        height: 100px;
                        margin: 0 auto;
                      }
                    }
                    @media only screen and (min-width: 728px) and (min-height: 0px) {
                      div[id^="wrapper-sevio-9ad182de-b462-428c-b5b0-9b4962b113f7"] {
                        width: 728px;
                        height: 90px;
                        margin: 0 auto;
                      }
                    }
                    @media only screen and (min-width: 970px) and (min-height: 0px) {
                      div[id^="wrapper-sevio-9ad182de-b462-428c-b5b0-9b4962b113f7"] {
                        width: 970px;
                        height: 90px;
                        margin: 0 auto;
                      }
                    }
                  `}</style>
                </Head>

                <Script async src='https://cdn.adx.ws/scripts/loader.js' />
                <EventTracker />

                {!isWidget && bannerVisible && (
                  <>
                    {/* Ad Section */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100px',
                        position: 'relative', // Added for positioning the close button
                      }}
                    >
                      {/* Close button */}
                      <button
                        onClick={handleCloseBanner}
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '10px',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        <img src='/assets/icons/icon-close.svg' alt='Close' />
                      </button>

                      {/* Ad Banner */}
                      <div
                        id='wrapper-sevio-9ad182de-b462-428c-b5b0-9b4962b113f7'
                        style={{ display: 'inline-block' }}
                      >
                        <div
                          className='sevioads'
                          data-zone='9ad182de-b462-428c-b5b0-9b4962b113f7'
                        ></div>
                        <Script
                          id='ad-script'
                          dangerouslySetInnerHTML={{
                            __html: `
                            window.sevioads = window.sevioads || [];
                            var sevioads_preferences = [];
                            sevioads_preferences[0] = {};
                            sevioads_preferences[0].zone = "9ad182de-b462-428c-b5b0-9b4962b113f7";
                            sevioads_preferences[0].adType = "banner";
                            sevioads_preferences[0].inventoryId = "006044c7-662f-44ee-8088-48943fb71bcb";
                            sevioads_preferences[0].accountId = "542bf6ba-fa5f-49cb-b028-b9589d251b56";
                            sevioads.push(sevioads_preferences);
                            `,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <Layout {...pageProps}>
                  <Component {...pageProps} />
                </Layout>
                <Toast />
                <EmailVerificationMessage />
                {!isWidget && (
                  <>
                    {isAuth && <FormBanner />}
                    <CookiesBanner />
                    <StickyCorner position={'left'} />
                    <StickyCorner position={'right'} />
                  </>
                )}
              </RawIntlProvider>
            </SWRConfig>
          </ErrorBoundaryRefresh>
        </OverlayProvider>
      </SSRProvider>
    </SuperTokensWrapper>
  )
}

MyApp.getInitialProps = ({ router }) => {
  const currentEntityByLocation = router.asPath.includes('exchange')
    ? ENTITY.EXCHANGE
    : ENTITY.COIN

  return {
    pageProps: {
      currentEntityByLocation,
    },
  }
}

export default wrapper.withRedux(MyApp)
